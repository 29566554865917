import React from 'react';
import './App.css';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

let token = null;

const isAuthenticated = () => !!token;

const clientId = '5gn6fogo6sv1cqg5tjoqmn6l0q';
const redirectUri = 'http://localhost:3001/oauth/cognito/callback';

var accessToken = new URLSearchParams(window.location.hash.split('#')[1]).get('access_token');
if(accessToken) token = accessToken


export default function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <PrivateRoute path="/users">
            <Users />
          </PrivateRoute>
          <Route path="/">
            <Home />
          </Route>          
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}






function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        if(isAuthenticated()) {
          return children;
        } else {
          window.location.href = `https://auth.keybinders.com/login?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`
        }
      }
    }
    />
  );
}
